<template>
    <section>
        <!--列表内容-->
         <el-tooltip  placement="top" content="双击编辑任务类型配置">
          <el-table
            :data="types" 
            highlight-current-row
            :row-style="{height:'40px'}"
            :cell-style="{padding:'0px'}"
            :header-cell-style="{background:'#dce4f1',color:'#000000'}"
            :row-class-name="tableRowClassName"
            @row-dblclick="checkconfig"
            style="width: 100%">
            <el-table-column type = "index">
            </el-table-column>
            <el-table-column label="任务类型名称" prop="Name" align="center"></el-table-column>
            <el-table-column label="是否需上级审核" prop="IsNeedUpLeaderApproval" align="center">
                <template slot-scope = "scope">
                    <el-tag v-if="scope.row.IsNeedUpLeaderApproval == 1" type="danger" effect="dark">是</el-tag>
                    <el-tag v-else type="success" effect="dark">否</el-tag></template> 
            </el-table-column>
            <el-table-column label="最后一次设置时间" prop="LastConfigTime" :formatter="formatLastConfigTime" align="center"></el-table-column>
            </el-table>
            </el-tooltip>
            <!--审核界面-->
            <el-dialog
            title="配置" 
            :visible.sync="addFormVisible" 
            v-model="addFormVisible"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            style="width: 60%;left: 20%;"
            center>
            <el-form :model="addForm" style="white-space:nowrap;" :rules="addFormRule">
                <el-form-item label="是否需上级审核:" prop="IsNeedUpLeaderApproval">
                      <el-select placeholder="请选择否需上级审核" v-model="addForm.IsNeedUpLeaderApproval">
                        <el-option v-for = "item in assignList" :key = "item.value" :value = "item.value" :label = "item.label">
                        </el-option>
                     </el-select>
                       <el-tooltip placement="right" content="此选项将决定该类型在日常任务录入后先由上级领导审核">
                             <!-- <span style="font-size:30px;">？</span> -->
                             <i class="el-icon-question" style="margin-left:10px; font-size: 25px;"></i>
                        </el-tooltip>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addSubmit">设置</el-button>
            </div>
         </el-dialog>
    </section>
</template>
<script>
import util from '../../../util/date';
import {Loading} from 'element-ui'
import {QueryAllClassifyConfigList,SetCommonClassifyConfig} from '../../api/oa';
export default {
    data(){
        return{
            loading: '',
            types: [],
            assignList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 0,
                    label: "否"
                }
            ],
            addFormVisible: false,
            addFormRule: {
                IsNeedUpLeaderApproval: [
                    {required: true, message: '请选择否需上级审核', trigger: 'blur'}
                ]
            },
            addForm: {
                Id: null,
                IsNeedUpLeaderApproval: null
            }
        }
    },
    methods: {
            formatLastConfigTime: function (row,colume) {
                return (!row.LastConfigTime || row.LastConfigTime == '') ? '' : util.formatDate.format(new Date(row.LastConfigTime),'yyyy-MM-dd hh:ss');
            },
            getTypes(){
                QueryAllClassifyConfigList().then((res) => {
                    this.types = res.data.response;
                });
            },
            checkconfig(row){
                if(row.Name == "日常工作"){
                     this.$message({
                        message: "日常工作无需上级领导审核。",
                        type: "warning",
                        duration: 10000
                    });
                    return;
                }
                this.addFormVisible = true;
                this.addForm.Id = row.Id;
                this.addForm.IsNeedUpLeaderApproval = row.IsNeedUpLeaderApproval;       
            },
            //改变审核状态
            addSubmit(){
                let para = Object.assign({},this.addForm);
                var user = JSON.parse(window.localStorage.user);
                para.LastConfigTime = util.formatDate.format(new Date(), 'yyyy-MM-dd');
                para.LastConfigUserCode = user.sub;
                para.LastConfigUserName = user.name;
                this.$confirm('此改变将影响日常任务的使用流程，确定要修改吗？','提示',{}).then(() => {
                    this.Loadingstart();
                    SetCommonClassifyConfig(para).then((res) => {
                        if (res.data.success) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.Loadignend();
                        this.addForm.IsNeedUpLeaderApproval = null;
                        this.addFormVisible = false;
                        this.getTypes();
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
               });
            },
             tableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
            return 'warning-row';
            }
            return '';    
       },
       Loadingstart(){
        　　this.loading = Loading.service({
        　　　　lock:true,
        　　　　text:'加载中...',
        　　　　background:'rgba(0,0,0,0.1)'
        　　})
        },
        Loadignend(){
        　　this.loading.close();
        }
    },
    mounted() {
            this.getTypes();
    }
}
</script>
<style scoped>
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
  }
  /deep/.el-table .warning-row {
    background: #F0F8FF;
  }
</style>